<template>
    <v-container fluid style="padding-bottom: 50px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal"
                                        v-model="modal"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            outlined
                                            dense
                                            v-model="date_from"
                                            label="Begin Period"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            type="month"
                                            v-model="date_from"
                                            no-title
                                            @input="modal = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col sm="6" md="2" class="col-12">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-account-box"
                                        clearable
                                        dense
                                        solo
                                        v-model="vendor" 
                                        :items="vendors" 
                                        item-value="vendor_id" 
                                        item-text="vendor_data" 
                                        label="Vendor" 
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        @change="(event) => [getThick(event), getWidth(event)]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-arrow-up-down"
                                        clearable
                                        solo
                                        v-model="thick"
                                        :items="thickness"
                                        item-value="thick"
                                        :item-text="item => $store.getters.convertToCurrency(item.thick)"
                                        label="Thick"
                                        dense
                                        class="ma-0 border-12" 
                                        hide-details=true
                                        :loading="loading_thick"
                                    ></v-autocomplete>                                    
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-arrow-left-right"
                                        clearable
                                        solo
                                        v-model="width"
                                        :items="widths"
                                        item-value="width"
                                        :item-text="item => $store.getters.convertToCurrency(item.width)"
                                        label="Width"
                                        dense
                                        class="ma-0 border-12" 
                                        hide-details=true
                                        :loading="loading_width"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="4">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="12">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers_sum"
                                    :items="summaries"
                                    class="elevation-1"
                                    :items-per-page="-1"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="350"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItemSum" 
                                    :item-class="tr_datatable_outstanding"
                                    hide-default-footer
                                    @click:row="detailArrival"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Summary </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemSum" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.thick`]="{ item }">
                                        {{$store.getters.convertToCurrency(item.thick)}}
                                    </template>
                                    <template v-slot:[`item.width`]="{ item }">
                                        {{$store.getters.convertToCurrencyNoFrac(item.width)}}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="histories"
                                    class="elevation-1"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="loading_detail"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItemTrans" 
                                    :item-class="tr_datatable"
                                    group-by="vendor_name"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Detail </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-btn
                                                small
                                                color="#005c37"
                                                class="py-5 mr-3 text-white border-12"
                                                >
                                                    <v-icon>mdi-file-excel</v-icon>
                                                    <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :data="histori_exports"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                    </download-excel>
                                                </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.thick`]="{ item }">
                                        {{$store.getters.convertToCurrency(item.thick)}}
                                    </template>
                                    <template v-slot:[`item.width`]="{ item }">
                                        {{$store.getters.convertToCurrencyNoFrac(item.width)}}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            search:'',
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'CRC, Coil & AZ',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'CRC Arrival Estimation',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            modal: false,
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            searchItemTrans: '',
            histories: [],
            headers: [],
            months: [],
            histori_exports: [],            
            searchItem: '',
            vendors: [],
            vendor: '',
            thickness: [],
            thick: '',
            widths: [],
            width:'',
            loading_thick: false,
            loading_width: false,
            summaries: [],
            choice: null,
            searchItemSum: '',
            headers_sum: [],
            summary: null,
            loading_detail: false
        }
    },
    computed:{
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)  
        this.getMonth(this.date_from,12)
        // await this.getEnvConf()
        await this.getVendor()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable_outstanding(item) {
            var rowClass = 'tr_datatable';
            if (this.summary) {
                if(this.summary.thick === item.thick && this.summary.width === item.width){
                    rowClass = 'tr_choice'
                } else {
                    rowClass = 'tr_datatable'
                }
            } else {
                rowClass = 'tr_datatable'
            }
            return rowClass;
        },
        tr_datatable(item) {
            var rowClass = 'tr_datatable_history'
            return rowClass;
        },
        clear(){
            if (this.region_disabled == false) {
                this.region = ''
            }
            if (this.office_disabled == false) {
                this.office = ''
            }
            if (this.sales_disabled == false) {
                this.sales = ''
            }
            if (this.division_disabled == false) {
                this.division = ''
            }
            this.vendor = ''
            this.thick = ''
            this.width = ''
        },
        async getEnvConf(){
            const respDataRegion = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=GBRK&appl_id=WEBGBRK&var_id=REGION&groupid=${this.$store.state.gbrk.group_id}`, null, false, false, false)  
            
            if (respDataRegion.status == 200) {
                this.region = respDataRegion.data.data ? respDataRegion.data.data : ''
                this.region_disabled = respDataRegion.data.data ? true : false
                if (this.region) {
                    await this.getOffice(this.region)
                }
            } else {
                this.region = ''
                this.region_disabled = false
            }

            const respDataOffice = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=GBRK&appl_id=WEBGBRK&var_id=OFFICEID&groupid=${this.$store.state.gbrk.group_id}`, null, false, false, false)  
            
            if (respDataOffice.status == 200) {
                this.office = respDataOffice.data.data ? respDataOffice.data.data : ''
                this.office_disabled = respDataOffice.data.data ? true : false
                if (this.office) {
                    await this.getRegion()
                    await this.getOffice(this.office)
                    await this.getSales(this.office)
                }
            } else {
                this.office = ''
                this.office_disabled = false
            }

            const respDataDivision = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=GBRK&appl_id=WEBGBRK&var_id=DIVISION&groupid=${this.$store.state.gbrk.group_id}`, null, false, false, false)  
            
            if (respDataDivision.status == 200) {
                this.division = respDataDivision.data.data ? respDataDivision.data.data : ''
                this.division_disabled = respDataDivision.data.data ? true : false
            } else {
                this.division = ''
                this.division_disabled = false
            }

            const respDataSales = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=GBRK&appl_id=WEBGBRK&var_id=SALESID&groupid=${this.$store.state.gbrk.group_id}`, null, false, false, false)  
            
            if (respDataSales.status == 200) {
                if (this.$store.state.gbrk.group_id == 'RM GBRK' || this.$store.state.gbrk.group_id == 'KACAB GBRK') {
                    this.sales = ''
                    this.sales_disabled = false
                    
                } else {
                    this.sales = respDataSales.data.data ? respDataSales.data.data : ''
                    this.sales_disabled = respDataSales.data.data ? true : false
                    if (this.sales) {
                        await this.getSales(this.sales)
                        await this.getCustomer(this.sales)
                    }
                }
                
            } else {
                this.sales = ''
                this.sales_disabled = false
            }
        },
        async getVendor(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/vendor`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.vendors = res.data
            });
        },    
        async getThick(event){
            if (event == '' || event == null) {
                this.thick = ''
            } else {
                this.loading_thick = true
                await axios.get(`${process.env.VUE_APP_URL}/api/v3/sr/crc-arrival/thick?thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&vendor_id=${this.vendor ? this.vendor : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_thick = false
                    this.thickness = res.data
                });
            }
            
        },    
        async getWidth(event){
            if (event == '' || event == null) {
                this.thick = ''
            } else {
                this.loading_width = true
                await axios.get(`${process.env.VUE_APP_URL}/api/v3/sr/crc-arrival/width?thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&vendor_id=${this.vendor ? this.vendor : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_width = false
                    this.widths = res.data
                });
            }
            
        },    
        choiceCrc(summary, index){
            this.choice = index
            console.log(summary, index);
        },
        getMonth(start, value){
            this.months = []
            this.headers = []
            const monthString = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            for (let index = 0; index <= value; index++) {
                if (index == 0) {
                    var startDate = new Date( Date.parse(start) - (new Date()).getTimezoneOffset() * 60000)
                    let name = monthString[startDate.getMonth()];
                    this.months.push({
                        value: start.replace(/-/g,""),
                        text: start
                    })
                } else {
                    var startDate = new Date( Date.parse(start) - (new Date()).getTimezoneOffset() * 60000)
                    var startDayofMonth = new Date(startDate.getFullYear(), (startDate.getMonth() + 1) + index, 0);
                    // console.log(startDayofMonth);
                    var month = (new Date(startDayofMonth - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
                    let name = monthString[(new Date(startDayofMonth - (new Date()).getTimezoneOffset() * 60000)).getMonth()];

                    this.months.push(
                        {
                            value: month.replace(/-/g,""),
                            text: month
                        }    
                    )
                }                  

            }

            this.headers = [
                { text: 'Vendor', value: 'vendor_name', width:300, align: 'left' },
                { text: 'Thick', value: 'thick', width:100, align: 'left', groupable:false, class: 'my-border' },
                { text: 'Width', value: 'width', width:100, align: 'left', groupable:false, class: 'my-border' },
                { text: this.months[0].text + ' (TON)', value: this.months[0].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[1].text + ' (TON)', value: this.months[1].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[2].text + ' (TON)', value: this.months[2].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[3].text + ' (TON)', value: this.months[3].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[4].text + ' (TON)', value: this.months[4].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[5].text + ' (TON)', value: this.months[5].value, width:100, align: 'right', groupable:false, class: 'my-border' }
            ]

            this.headers_sum = [
                { text: 'Thick', value: 'thick', width:100, align: 'left', groupable:false, class: 'my-border' },
                { text: 'Width', value: 'width', width:100, align: 'left', groupable:false, class: 'my-border' },
                { text: this.months[0].text + ' (TON)', value: this.months[0].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[1].text + ' (TON)', value: this.months[1].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[2].text + ' (TON)', value: this.months[2].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[3].text + ' (TON)', value: this.months[3].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[4].text + ' (TON)', value: this.months[4].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[5].text + ' (TON)', value: this.months[5].value, width:100, align: 'right', groupable:false, class: 'my-border' }
            ]


        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true)  

            this.getMonth(this.date_from, 6)

            var reqBody = {
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'vendor_id': this.vendor ? this.vendor : '',
                'startPeriod': this.months.length > 0 ? this.months[0].value : '',
                'endPeriod': this.months.length > 0 ? this.months[5].value : '',
                'months' : this.months,
            }

            const respDataSummary = await backendApi.fetchCore('/api/v3/sr/crc-arrival/summary', reqBody, false, false, false)
            // const respData = await backendApi.fetchCore('/api/v3/sr/crc-arrival', reqBody, false, false, false)
            if (respDataSummary.status === 200) {
                // this.histories = respData.data
                this.summaries = respDataSummary.data
                this.$store.dispatch('setOverlay', false)  
            } else {
                this.$store.dispatch('setOverlay', false)  

            }

        },
        async detailArrival(e){
            this.summary = e
            this.loading_detail = true 

            this.getMonth(this.date_from, 6)

            var reqBody = {
                'thick': e.thick ? e.thick : '',
                'width': e.width ? e.width : '',
                'vendor_id': this.vendor ? this.vendor : '',
                'startPeriod': this.months.length > 0 ? this.months[0].value : '',
                'endPeriod': this.months.length > 0 ? this.months[5].value : '',
                'months' : this.months,
            }

            const respData = await backendApi.fetchCore('/api/v3/sr/crc-arrival', reqBody, false, false, false)
            if (respData.status === 200) {
                this.histories = respData.data
                this.loading_detail = false 
            } else {
                this.loading_detail = false 

            }
        },
        async exportExcel(){
            this.getMonth(this.date_from, 6)

            var reqBody = {
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'vendor_id': this.vendor ? this.vendor : '',
                'startPeriod': this.months.length > 0 ? this.months[0].value : '',
                'endPeriod': this.months.length > 0 ? this.months[5].value : '',
                'months' : this.months,
            }

            const respData = await backendApi.fetchCore('/api/v3/sr/crc-arrival', reqBody, false, false, false)
            if (respData.status === 200) {
                this.histori_exports = respData.data

                return this.histori_exports
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        }
    },    
}
</script>